.MuiAppBar-root[data-cc-component="NavBar"] {
  background-image: linear-gradient(to bottom, #5abced, #1498d8);

  .MuiToolbar-root {
    min-height: 36px;
  }

  .MuiTabs-root {
    width: 100%;
    min-height: unset;

    .MuiTabs-indicator {
      height: 4px;
      background-color: #ffffff;
    }

    .MuiTab-root {
      width: 160px;
      min-width: unset;
      max-width: unset;
      min-height: 36px;
      margin: 0 12px 0 12px;
      padding: unset;
      opacity: unset;
      font-size: 14px;
      font-weight: unset;
      line-height: unset;
      text-align: center;
      color: #ffffff;

      &:hover {
        box-shadow: inset 0 -4px 0 0 #8dcff0;
      }

      &.Mui-selected {
        font-weight: 600;
      }

      .MuiTab-wrapper {
        width: unset;
      }
    }
  }
}

.MuiButton-root[data-cc-component="NavMenu"] {
  z-index: 1;
  display: flex;
  position: absolute;
  right: 0;
  margin: 0 8px;
  padding: 3px 4px 3px 8px;
  align-items: center;
  border-radius: 4px;
  border: solid 1px transparent;

  &[data-active="true"],
  &:active,
  &:hover:active {
    background-color: #7bbbe2;
    background-blend-mode: overlay;
    border: solid 1px transparent;
  }

  &:hover {
    background-color: #7bbbe2;
    border: solid 1px rgba(255, 255, 255, 0.35);
  }

}

.MuiButton-root[data-cc-component="NavMenu"].MuiButton-text {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
}

.MuiMenu-list[data-cc-component="NavMenu"] {

  .MuiMenuItem-root {
    padding: 14px 8px;

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .MuiTypography-root {
      flex-grow: 1;
      font-size: 14px;
    }
  }
}

.MuiButton-root[data-cc-component="PlatformMenu"].MuiButton-text {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
}

.MuiMenu-list[data-cc-component="PlatformMenu"] {

  .MuiMenuItem-root {
    padding: 10px 8px;

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      margin-right: 15px;
      margin-top: -20px;
    }
  }
}