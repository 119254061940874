.Pulse {
  .PulseDot1,
  .PulseDot2,
  .PulseDot3 {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    background-color: #ffffff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: puslse 1.4s infinite ease-in-out both;
    animation: puslse 1.4s infinite ease-in-out both;
  }

  .PulseDot1 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .PulseDot2 {
    margin-left: 2px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .PulseDot3 {
    margin-left: 2px;
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s;
  }
}

@-webkit-keyframes puslse {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes puslse {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
