.MuiButton-root[data-cc-component="CCLoginButton"] {
  height: 44px;
  margin-top: 34px;
  border-radius: 100px;
  background-image: linear-gradient(to bottom, #5abced, #1498d8);

  span {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
  }

  &[data-animate="true"] {
    .MuiButton-label {
      position: relative;
      display: flex;
      align-self: center;
    }
  }

  &[data-animate="false"] {
    &:hover,
    &:active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    &:active {
      background-color: #262837;
      background-blend-mode: difference;
    }

    &:disabled {
      span {
        opacity: 0.45;
      }
    }
  }
}
