.HelpText {
  margin-top: 12px;
  font-size: 13px;
  text-align: center;
  color: #5abced;
}

.Pulse {
  position: absolute;
  width: 100%;
  //height: 100%;
  overflow: visible;
}

.EnlilLogo {
  position: absolute;
  width: 58.5px;
  height: 50px;
  opacity: 1;
}