.PagesButton {
  color: #525968;
  margin: 0 0 0 7px;
  padding: 10px 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #FAFAFA;
  height: 30px;
  min-width: 25px;

  &:hover {
    background-color: #FAFAFA;
    border-radius: 5px;
    color: #1498D8;
  }

  &:active {
    background-color: #FAFAFA;
    border-radius: 5px;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FAFAFA;
  }
}


.NavMenuButton {
  color: #525968;
  margin: 0 0 0 0;
  padding: 10px 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 30px;
  min-width: 30px;

  &:hover {
    background-color: #ffffff;
    border-radius: 5px;
    color: #1498D8;
  }

  &:active {
    background-color: #ffffff;
    border-radius: 5px;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}


.DashboardMenuButton {
  color: #FFFFFF;
  margin: 6px 15px 0 0;
  padding: 10px 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #1498D8;
  height: 30px;
  min-width: 30px;

  &:hover {
    background-color: #1498D8;
    border-radius: 5px;
    color: #777777;
  }

  &:active {
    background-color: #1498D8;
    border-radius: 5px;
    color: #777777
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.AppMenuButton {

  &:hover {
    color: #004E75;
    background-color: #ffffff;
  }

  &:active {
    color: #004E75;
    background-color: #ffffff;
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}


.Link {
  color: #000000;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 15px;
  background-color: #FAFAFA;
  text-decoration-color: #1498D8;
  cursor: pointer;

  &:hover {
    background-color: #FAFAFA;
    color: #1498D8;
  }

  &:active {
    background-color: #FAFAFA;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FAFAFA;
  }
}

.Link2 {
  color: #1498D8;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  background-color: #FFFFFF;
  text-decoration-color: #1498D8;
  cursor: pointer;

  &:hover {
    background-color: #FFFFFF;
    color: #1498D8;
    text-decoration: underline;
  }

  &:active {
    background-color: #FFFFFF;
    color: #1498D8;
    text-decoration: underline;
  }

  &:disabled {
    color: #FFFFFF;
    background-color: #1498D8;
  }
}

.LinkCategory{
  color: #000000;
  padding: 0 0 3px 0;
  text-align: left;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #1498D8;
    text-decoration-color: #1498D8;
  }

  &:active {
    background-color: #FAFAFA;
    color: #1498D8
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FAFAFA;
  }
}

.Link3 {
  color: #616161;
  padding: 0 0 3px 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    color: #1498D8;
    text-decoration-color: #1498D8;
  }

  &:active {
    background-color: #FAFAFA;
    color: #1498D8
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FAFAFA;
  }
}

.Link4 {
  color: #616161;
  padding: 0 0 3px 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #1498D8;
    text-decoration-color: #1498D8;
  }

  &:active {
    background-color: #FAFAFA;
    color: #1498D8
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FAFAFA;
  }
}


.SubMenuButton {
  color: #525968;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 12px;
  background-color: transparent;
  height: 25px;
  min-width: 30px;

  &:hover {
    background-color: transparent;
    color: #525968;

  }

  &:active {
    background-color: transparent;
    color: #525968
  }

  &:disabled {
    color: #525968;
    background-color: transparent;
  }
}

.ViewByButton {
  color: #525968;
  margin: 1px 0 0 0;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #FAFAFA;
  height: 18px;
  min-width: 25px;

  &:hover {
    background-color: #FAFAFA;
    border-radius: 5px;
    color: #1498D8;
  }

  &:active {
    background-color: #FAFAFA;
    border-radius: 5px;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #FAFAFA;
  }
}

.SubMenuDiv {
  background-color: #FAFAFA;
  height: 25px;

  &:active,
  &:hover:active {
    background-color: #EEEEEE;
    background-blend-mode: overlay;
  }

  &:hover {
    background-color: #EEEEEE;
  }
}