.MuiTextField-root[data-cc-component="CCTextField"] {
  &[data-size="small"] {
    font-size: 14px;
  }

  .MuiInputBase-root {
    font-size: inherit;
    color: #262837;

    ::placeholder {
      color: #bac3ce !important;
      opacity: unset !important;
      line-height: normal !important;
    }

    .Mui-disabled {
      -webkit-text-fill-color: unset;
    }
  }

  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0;

    .MuiSvgIcon-root {
      color: #d8dde4;
    }
  }

  .MuiFormHelperText-root {
    height: 18px;
    margin: 8px 0 0 0;
    font-size: 12px;

    .Mui-error {
      color: #e74c3c;
    }
  }
}
