
.EnlilLogo {
  position: absolute;
  width: 58.5px;
  height: 50px;
  opacity: 1;
}

.NavMenuButton {
  color: #525968;
  margin: 0 0 0 0;
  padding: 10px 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 30px;
  min-width: 30px;

  &:hover {
    background-color: #ffffff;
    border-radius: 5px;
    color: #1498D8;
  }

  &:active {
    background-color: #ffffff;
    border-radius: 5px;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.AppMenuButton {

  &:hover {
    background-color: #ffffff;
  }

  &:active {
    background-color: #ffffff;
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.GetEnlilButton {
  color: #525968;
  margin: 6px 0 0 0;
  padding: 0 0 0 0;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 30px;
  min-width: 30px;
  border: 1px solid #1498D8;
  width: 80px;

  &:hover {
    background-color: #ffffff;
    border-radius: 5px;
    color: #1498D8;
  }

  &:active {
    background-color: #ffffff;
    border-radius: 5px;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.Standard {
  background-color: #FFFFFF;

  &:active,
  &:hover:active {
    background-color: #D8DDE4;
    background-blend-mode: overlay;
    border: solid 1px transparent;
  }

  &:hover {
    background-color: #D8DDE4;
  }
}

.Mobile {
  background-color: #FFFFFF;
  border-bottom: solid 1px #EEEEEE;
  height: 35px;
  padding-left: 15px;
  padding-top: 11px;

  &:active,
  &:hover:active {
    background-color: #D8DDE4;
    background-blend-mode: overlay;
    border: solid 1px #EEEEEE;
  }

  &:hover {
    background-color: #D8DDE4;
  }
}