.MenuButton {
  color: #525968;
  margin: 0 0 0 0;
  padding: 0 5px 0 5px;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: 14px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 29px;
  min-width: 35px;

  &:hover {
    background-color: #ffffff;
    border-radius: 5px;
    color: #1498D8;
  }

  &:active {
    background-color: #ffffff;
    border-radius: 5px;
    color: #525968
  }

  &:disabled {
    color: #D8DDE4;
    background-color: #ffffff;
  }
}

.Standard {
  background-color: #FFFFFF;

  &:active,
  &:hover:active {
    background-color: #D8DDE4;
    background-blend-mode: overlay;
    border: solid 1px transparent;
  }

  &:hover {
    background-color: #D8DDE4;
  }
}